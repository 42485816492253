import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../providers/ApiProvider';
import { ErrorApiResponse, GoodApiResponse, IndexParams, SearchFormation } from '../types/api';
import { Region, Site, Metier } from '../types/visitorRessources';
import { handleError } from '../utils/ErrorHandler';
import { calculateDistance } from '../utils/Haversine';

/**
 * Ce composant affiche la barre de recherche, côté Front.
 *
 * @param className - Classes Tailwind de l'élément de premier niveau.
 * @param initialSearchState - État de recherche initial pour la recherche textuelle, les metiers et les sites.
 * @param fromBackOffice - Précise si le composant est appelé depuis le Front-Office ou le Back-Office.
 */
export const SearchBar: FunctionComponent<{ className: string; initialSearchState?: SearchFormation; fromBackOffice?: boolean }> = ({
  className,
  initialSearchState = { recherche: '', selectedMetiers: [], selectedSites: [] },
  fromBackOffice = false,
}) => {
  /**
   * Stocke les metiers
   */
  const [metiers, setMetiers] = useState<Metier[] | null>(null);
  /**
   * Stocke les régions pour la liste des sites
   */
  const [regions, setRegions] = useState<Region[] | null>(null);

  /**
   * Liste des sites
   */
  const [sites, setSites] = useState<Site[] | null>(null);

  /**
   * État de la recherche courant. Par défaut `initialSearchState`.
   */
  const [searchState, setSearchState] = useState<SearchFormation>(initialSearchState);

  /**
   * Contient la liste des sites sélectionné.
   */
  const [displayedSearchedSites, setDisplayedSearchedSites] = useState<Site['nom'][]>([]);
  /**
   * Contient la liste des metiers sélectionné.
   */
  const [displayedSearchedMetiers, setDisplayedSearchedMetiers] = useState<Metier['slug'][]>([]);
  /**
   * Régions sélectionnée.
   */
  const [selectedRegions, setSelectedRegions] = useState<Region['slug'][]>([]);
  /**
   * Metiers sélectionnée.
   */
  const [selectedMetiers, setSelectedMetiers] = useState<Metier['slug'][]>([]);

  /**
   * Permet au composant de changer de page.
   */
  const navigate = useNavigate();

  /**
   * On à besoin d'initialiser le client d'API à l'aide d'un hook personalisé.
   */
  const client = useApi();

  /**
   * Etat pour stocker les suggestions.
   */
  const [suggestions, setSuggestions] = useState<Site[]>([]);

  /**
   * Etat pour gérer l'affichage/masquage de la liste des suggestions.
   */
  const [showSuggestions, setShowSuggestions] = useState(false);

  /**
   * Etat pour suivre l'état de chargement.
   */
  const [isLoading, setIsLoading] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  /**
   * Va chercher les informations dans l'API pour afficher les sites et les régions voulues au chargement du composant.
   */
  useEffect(() => {
    handleIndex();
    metiersIndex();
    initialSearchState.selectedMetiers.map((metier) => handleCheckboxInit(metier));
  }, [client]);

  /**
   * Cette méthode va chercher les informations dans l'API pour afficher les sites et les régions voulues.
   */
  const handleIndex = () => {
    client
      .get<GoodApiResponse<Region[]>, IndexParams>('/regions-sites', { params: { no_pagination: true, sort: 'nom' } })
      .then((response) => {
        setRegions(response.data.data);

        let sites: Site[] = [];

        (response.data.data as Region[]).forEach((region) => {
          if (region.sites) {
            sites = [...sites, ...region.sites];
          }
        });

        setSites([...sites]);

        setDisplayedSearchedSites([...sites.filter((site) => initialSearchState.selectedSites.includes(site.slug)).map((site) => site.nom)]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      });
  };

  /**
   * Cette méthode va chercher les informations dans l'API pour afficher les metiers.
   */

  const metiersIndex = () => {
    client
      .get<GoodApiResponse<Metier[]>, IndexParams>('/metiers', { params: null })
      .then((response) => {
        setMetiers(response.data.data);
        setDisplayedSearchedMetiers([
          ...response.data.data.filter((metier) => initialSearchState.selectedMetiers.includes(metier.slug)).map((metier) => metier.slug),
        ]);
      })
      .catch((error: ErrorApiResponse) => {
        handleError(error);
      });
  };

  /**
   * Fermer automatiquement la liste des suggestions en cliquant Out.
   **/
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  /**
   * Cette méthode permet de charger une liste de suggestion à partir de l'entrée d'utilisateur (auto-complete)
   * @param keywords
   * @param selectedSites
   */
  const fetchSuggestions = (keywords: string[], selectedSites: string[]) => {
    if (keywords && keywords.length > 0 && keywords[0].length >= 3) {
      setIsLoading(true);
      const params: { texte: string; sites?: string } = {
        texte: keywords.join(' '),
      };

      if (selectedSites && selectedSites.length > 0) {
        params.sites = selectedSites.join('|');
      }

      client
        .get<GoodApiResponse<Site[]>>('/formations/recherche', { params })
        .then((response) => {
          setSuggestions(response.data.data.slice(0, 5)); // Limitation de nombre de suggestion à 5
        })
        .catch((error) => {
          console.log(error);
          // handleError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setSuggestions([]); // Si pas de mots-clés, on cache les suggestions
    }
  };

  /**
   * Cette méthode effectue concrètement la recherche.
   */
  const handleSearch = () => {
    if (fromBackOffice) {
      window.open(
        `/resultats-recherche?recherche=${searchState.recherche}&sites=${searchState.selectedSites.join('|')}&metiers=${searchState.selectedMetiers.join('|')}`,
      );
    } else {
      navigate(
        `/resultats-recherche?recherche=${searchState.recherche}&sites=${searchState.selectedSites.join('|')}&metiers=${searchState.selectedMetiers.join('|')}`,
      );
    }
  };

  /**
   * Cette méthode permet de gérer les changements à l'interieur des champs texte du formulaire et de gérer les valeurs des champs via un état local.
   *
   * @param field - Champs du formulaire dont on doit stocker la valeur et s'il est en erreur.
   */
  const handleInputChange = (field: keyof typeof searchState) => {
    return (event: { target: { value: string } }) => {
      const value = event.target.value;
      setSearchState((prev) => ({
        ...prev,
        [field]: value,
      }));

      if (field === 'recherche') {
        const selectedSites = searchState.selectedSites || [];
        if (value.length >= 2 || selectedSites.length > 0) {
          const keywords = value.split(' ').filter((word) => word.length > 0);
          fetchSuggestions(keywords, selectedSites);
        } else {
          setSuggestions([]); // Si la saisie est vide ou trop courte, on cache les suggestions
        }
      }
    };
  };

  // Fermer la liste si un clic est effectué en dehors de l'input
  const handleClickOutside = (event: MouseEvent) => {
    // Vérifie si le clic a été effectué en dehors du champ de recherche
    if (!(event.target instanceof HTMLElement) || !event.target.closest('.search-bar') || event.target.closest('li')) {
      setShowSuggestions(false);
    }
    if (!(event.target instanceof HTMLElement) || !event.target.closest('.metier-bar')) {
      setIsOpenMetier(false);
    }
    if (!(event.target instanceof HTMLElement) || !event.target.closest('.site-bar')) {
      setIsOpen(false);
    }
  };

  /**
   * "Gère" le clic sur sur un elément. Ca réagit contextuellement en fonction de ce sur quoi on clique.
   * L'idée c'est d'ajouter les sites dans un tableau et qu'il n'y ait pas de doublon.
   *
   * @param typeOfChoice - Type d'élément sur lequel on a cliqué.
   * @param value - Contientle slug du metier ou n'est pas défini.
   */
  const handleToggleMetier = (typeOfChoice: 'metier', value?: Metier) => {
    switch (typeOfChoice) {
      case 'metier':
        if (selectedMetiers.includes(value?.slug as Metier['slug'])) {
          (metiers as Metier[]).find((metier) => metier.slug === value?.slug) as Metier;

          setSelectedMetiers([...selectedMetiers.filter((selectedMetier) => selectedMetier !== value?.slug)]);
        }
        setSelectedMetiers([...selectedMetiers, value?.slug as Metier['slug']]);
        toogleMetier(value as Metier);
        setIsOpenMetier(true);
        break;
      default:
        break;
    }
  };
  /**
   * "Gère" le clic sur sur un elément. Ca réagit contextuellement en fonction de ce sur quoi on clique.
   * L'idée c'est d'ajouter les sites dans un tableau et qu'il n'y ait pas de doublon.
   *
   * @param typeOfChoice - Type d'élément sur lequel on a cliqué.
   * @param value - Contientle slug du site, de la region ou n'est pas défini.
   */
  const handleToggleSite = (typeOfChoice: 'tout' | 'autour' | 'region' | 'site', value?: Site | Region) => {
    switch (typeOfChoice) {
      case 'tout':
        setSearchState({
          ...searchState,
          selectedSites: [],
        });
        setSelectedRegions([]);
        setDisplayedSearchedSites([]);
        setIsOpen(false);
        setSuggestions([]);
        break;
      case 'autour':
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setSearchState({
              ...searchState,
              selectedSites: [
                ...searchState.selectedSites,
                ...(sites as Site[])
                  .filter(
                    (site) =>
                      site.geo_lat &&
                      site.geo_lng &&
                      calculateDistance(
                        { latitude: position.coords.latitude, longitude: position.coords.longitude },
                        { latitude: site.geo_lat, longitude: site.geo_lng },
                      ) < 50000 &&
                      !searchState.selectedSites.includes(site.slug),
                  )
                  .map((site) => site.slug),
              ],
            });

            setDisplayedSearchedSites([
              ...displayedSearchedSites,
              ...(sites as Site[])
                .filter(
                  (site) =>
                    site.geo_lat &&
                    site.geo_lng &&
                    calculateDistance(
                      { latitude: position.coords.latitude, longitude: position.coords.longitude },
                      { latitude: site.geo_lat, longitude: site.geo_lng },
                    ) < 50000 &&
                    !displayedSearchedSites.includes(site.nom),
                )
                .map((site) => site.nom),
            ]);

            toast.success('Les centres autour de vous ont été ajoutés.');
            setSuggestions([]);
          },
          () => {
            toast.error('Une erreur est survenue lors de la tentative de géolocalisation.');
          },
        );

        break;
      case 'region':
        if (selectedRegions.includes(value?.slug as Region['slug'])) {
          (((regions as Region[]).find((region) => region.slug === value?.slug) as Region).sites as Site[]).forEach((site) => {
            setSearchState((searchState) => ({
              ...searchState,
              selectedSites: [...searchState.selectedSites.filter((selectedSite) => selectedSite !== site.slug)],
            }));

            setDisplayedSearchedSites([...displayedSearchedSites.filter((displayedSearchedSite) => displayedSearchedSite !== site.nom)]);
          });

          setSelectedRegions([...selectedRegions.filter((selectedRegion) => selectedRegion !== value?.slug)]);
        } else {
          setSearchState({
            ...searchState,
            selectedSites: [
              ...searchState.selectedSites,
              ...(((regions as Region[]).find((region) => region.slug === value?.slug) as Region).sites as Site[])
                .filter((site) => !searchState.selectedSites.includes(site.slug))
                .map((site) => site.slug),
            ],
          });

          setDisplayedSearchedSites([
            ...displayedSearchedSites,
            ...(((regions as Region[]).find((region) => region.slug === value?.slug) as Region).sites as Site[])
              .filter((site) => !displayedSearchedSites.includes(site.nom))
              .map((site) => site.nom),
          ]);

          setSelectedRegions([...selectedRegions, value?.slug as Region['slug']]);
        }

        setSuggestions([]);
        break;
      case 'site':
        toogleSite(value as Site);
        setSuggestions([]);

        break;

      default:
        break;
    }
  };

  /**
   * Ajoute un site pour la recherche.
   *
   * @param value - Site que l'on souhaite ajouter.
   */
  const addSite = (value: Site) => {
    setSearchState({ ...searchState, selectedSites: [...searchState.selectedSites, value.slug] });

    setDisplayedSearchedSites([...displayedSearchedSites, value.nom]);
  };

  /**
   * Ajoute un metier pour la recherche.
   *
   * @param value - metier que l'on souhaite ajouter.
   */
  const addMetier = (value: Metier) => {
    setSearchState({ ...searchState, selectedMetiers: [...searchState.selectedMetiers, value.slug] });

    setDisplayedSearchedMetiers([...displayedSearchedMetiers, value.slug]);
  };

  /**
   * Retire un site de la recherche.
   * @param value - Site que l'on souhaite supprimer.
   */
  const removeSite = (value: Site) => {
    setSearchState((searchState) => ({
      ...searchState,
      selectedSites: [...searchState.selectedSites.filter((selectedSite) => selectedSite !== value.slug)],
    }));

    setDisplayedSearchedSites([...displayedSearchedSites.filter((displayedSearchedSite) => displayedSearchedSite !== value.nom)]);
  };
  /**
   * Retire un Metier de la recherche.
   * @param value - Metier que l'on souhaite supprimer.
   */
  const removeMetier = (value: Metier) => {
    setSearchState((searchState) => ({
      ...searchState,
      selectedMetiers: [...searchState.selectedMetiers.filter((selectedMetier) => selectedMetier !== value.slug)],
    }));

    setDisplayedSearchedMetiers([...displayedSearchedMetiers.filter((displayedSearchedMetier) => displayedSearchedMetier !== value.slug)]);
  };
  const handleCheckboxInit = (slug: string) => {
    // Ajouter l'élément s'il n'est pas présent
    setSelectedMetiers(
      (prev) =>
        prev.includes(slug)
          ? prev.filter((selectedMetier) => selectedMetier !== slug) // Retirer si déjà présent
          : [...prev, slug], // Ajouter si non présent
    );
  };
  const handleCheckboxChange = (slug: string) => {
    if (selectedMetiers.includes(slug)) {
      // Retirer l'élément s'il est déjà présent
      setSelectedMetiers(selectedMetiers.filter((selectedMetier) => selectedMetier !== slug));
    } else {
      // Ajouter l'élément s'il n'est pas présent
      setSelectedMetiers([...selectedMetiers, slug]);
    }
  };

  /**
   * Cette méthode permet d'ajouter ou de retirer un site si celui est déjà présent dans l'état de recherche.
   *
   * @param value - Site sur lequel on clique.
   */
  const toogleSite = (value: Site) => {
    if (value && searchState.selectedSites.includes(value.slug)) {
      removeSite(value);
    } else {
      addSite(value);
    }
  };

  /**
   * Cette méthode permet d'ajouter ou de retirer un metier si celui est déjà présent dans l'état de recherche.
   *
   * @param value - Site sur lequel on clique.
   */
  const toogleMetier = (value: Metier) => {
    if (value && searchState.selectedMetiers.includes(value.slug)) {
      removeMetier(value);
    } else {
      addMetier(value);
    }
  };

  /*------------test--------------*/
  /**
   * État local qui gère l'ouverture de la liste des metiersoù l'on peut cliquer.
   */
  const [isOpenMetier, setIsOpenMetier] = useState(false);

  /**
   * État local qui gère l'ouverture de la liste des sites et des régions où l'on peut cliquer.
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Gère de façon contextuelle l'ouverture de la liste des metiers où l'on peut cliquer.
   */
  const toggleMetier = () => {
    setIsOpenMetier((isOpenMetier) => !isOpenMetier);
  };

  /**
   * Gère de façon contextuelle l'ouverture de la liste des sites et des régions où l'on peut cliquer.
   */
  const toggle = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  /**
   * En cliquant "Entrer", la recherche est lancée.
   * @param event
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Fragment>
      <div className={className + ' flex flex-col md:flex-row gap-4 items-center justify-between'}>
        <div className='py-[13px] px-4 rounded-md w-full bg-white mb-3 md:mb-0'>
          <div className='relative search-bar'>
            <input
              className={`outline-none w-full px-3 block text-sm rounded ${
                searchState.recherche && suggestions && suggestions.length > 0 ? 'border-x-2 border-gray-300' : ''
              }`}
              type='text'
              placeholder='Effectuez une recherche par mots-clés...'
              value={searchState.recherche}
              onChange={(event) => {
                handleInputChange('recherche')(event);
              }}
              onKeyDown={handleKeyDown}
              onFocus={() => setShowSuggestions(true)}
            />
            {isLoading && (
              <svg className='absolute right-3 top-0 animate-spin h-5 w-5 text-primary' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 24c6.627 0 12-5.373 12-12h-4a8 8 0 01-8 8v4z'></path>
              </svg>
            )}
            {showSuggestions && searchState.recherche && suggestions && suggestions.length > 0 && !isLoading && (
              <ul className='absolute left-0 mt-1 z-10 w-full overflow-y-auto bg-white border-x-2 border-gray-300 rounded shadow-lg text-sm'>
                {suggestions.map((option) => (
                  <li
                    key={option.slug}
                    className='px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-700 lowercase'
                    onClick={() => {
                      // change nom with keyword after updating search API
                      // searchState.recherche length must be lower than 50
                      searchState.recherche = option.nom;
                    }}>
                    {option.nom}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className='flex flex-row relative justify-between rounded-md bg-white py-0 mb-3 md:mb-0 w-full'>
          <div
            className='metier-bar w-full cursor-pointer flex flex-row items-center justify-between mr-6 md:mr-5 text-gray-600 text-sm pl-1  ml-5 py-3.5'
            onClick={toggleMetier}>
            <div className='text-ellipsis overflow-hidden whitespace-nowrap w-40 md:w-40 lg:w-64'>
              {displayedSearchedMetiers.length > 0 ? displayedSearchedMetiers.join(', ') : 'Tous les metiers'}
            </div>
            <div className='w-4 h-4 basis-3'>
              <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={3} stroke='currentColor' className='w-full h-full'>
                <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
              </svg>
            </div>
          </div>
          {isOpenMetier && (
            <div className='bg-white p-4 rounded-lg absolute left-0 w-full mt-11 z-50 drop-shadow-lg'>
              <div className='max-h-[400px] overflow-y-scroll'>
                {metiers && (
                  <div className='border-t pt-3 mt-3'>
                    {metiers.map((metier, index) => (
                      <div key={index}>
                        <div key={index} className='flex flex-row gap-1' onClick={() => handleToggleMetier('metier', metier)}>
                          <input
                            type='checkbox'
                            className='text-red-600 cursor-pointer'
                            checked={selectedMetiers.includes(metier.slug)}
                            onChange={() => handleCheckboxChange(metier.slug)}
                          />

                          <p className='text-sm poppins-semibold text-secondary-1 mb-1 hover:text-primary cursor-pointer'>{metier.nom}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className='flex flex-row relative justify-between rounded-md bg-white py-0 mb-3 md:mb-0 w-full'>
          <div className='py-3 pl-4 pr-2 text-gray-600'>
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
              <path strokeLinecap='round' strokeLinejoin='round' d='M15 10.5a3 3 0 11-6 0 3 3 0 016 0z' />
              <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z' />
            </svg>
          </div>
          <div className='site-bar w-full cursor-pointer flex flex-row items-center justify-between mr-6 md:mr-5 text-gray-600 text-sm pl-1' onClick={toggle}>
            <div className='text-ellipsis overflow-hidden whitespace-nowrap w-40 md:w-40 lg:w-64'>
              {displayedSearchedSites.length > 0 ? displayedSearchedSites.join(', ') : 'Tous les centres'}
            </div>
            <div className='w-4 h-4 basis-3'>
              <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={3} stroke='currentColor' className='w-full h-full'>
                <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
              </svg>
            </div>
          </div>
          {isChecked}
          {isOpen && (
            <div className='bg-white p-4 rounded-lg absolute left-0 w-full mt-11 z-50 drop-shadow-lg'>
              <div className='max-h-[400px] overflow-y-scroll'>
                <div className='text-sm poppins-semibold text-secondary-1 pb-2' onClick={() => handleToggleSite('tout')}>
                  <span className='cursor-pointer  hover:text-primary'>Tous les centres</span>
                </div>
                <div className='text-sm poppins-semibold text-secondary-1' onClick={() => handleToggleSite('autour')}>
                  <span className='cursor-pointer  hover:text-primary'>Autour de moi</span>
                </div>
                {regions && (
                  <div className='border-t pt-3 mt-3'>
                    {regions.map((region, index) => (
                      <div key={index}>
                        <div key={index} className='flex flex-row gap-1' onClick={() => handleToggleSite('region', region)}>
                          <input
                            type='checkbox'
                            className='text-red-600 cursor-pointer'
                            checked={selectedRegions.includes(region.slug)}
                            onChange={(e) => setIsChecked(e.target.checked)} // Met à jour l'état lors du changement
                          />
                          <p className='text-sm poppins-semibold text-secondary-1 mb-1 hover:text-primary cursor-pointer'>{region.nom}</p>
                        </div>
                        <div className='pl-5'>
                          {region.sites &&
                            region.sites.map((site, index) => (
                              <div key={index} className='flex flex-row gap-1 text-xs py-0.5' onClick={() => handleToggleSite('site', site)}>
                                <input
                                  type='checkbox'
                                  className='text-red-600 cursor-pointer'
                                  checked={searchState.selectedSites.includes(site.slug)}
                                  onChange={(e) => setIsChecked(e.target.checked)} // Met à jour l'état lors du changement
                                />
                                <p className='cursor-pointer poppins'>{site.nom}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <button
          className={`w-full bg-primary p-3 text-white poppins-semibold ${
            fromBackOffice ? 'rounded-full' : 'rounded-md'
          } hover:shadow-lg transition duration-3000 cursor-pointer flex justify-center`}
          onClick={() => handleSearch()}>
          <div>{fromBackOffice ? 'Chercher une formation' : 'Recherche'}</div>
          <MagnifyingGlassIcon className='w-6 h-6 ml-2' />
        </button>
      </div>
    </Fragment>
  );
};
